<template>
  <div>
    <vue-recaptcha
      ref="recaptcha"
      :sitekey="sitekey"
      size="invisible"
      :load-recaptcha-script="true"
      @verify="verifiedHandler"
      @expired="resetCaptcha"
    />

    <slot :perform-verified-action="performVerifiedAction" />
  </div>
</template>

<script>
import VueRecaptcha from 'vue-recaptcha'

export default {
  components: {
    VueRecaptcha
  },
  props: {
    sitekey: {
      type: String,
      default: ''
    }
  },
  methods: {
    performVerifiedAction() {
      this.$refs.recaptcha.execute()
    },
    resetCaptcha() {
      this.$refs.recaptcha.reset()
    },
    verifiedHandler(token) {
      this.$emit('verified', token)
      this.resetCaptcha()
    }
  }
}
</script>

<style>
.grecaptcha-badge {
  visibility: hidden;
}
</style>
