<template>
  <div>
    <text-field
      v-model="form.email"
      type="email"
      :placeholder="$t('email')"
      :error-messages="errors.email"
      @input="errors.email = []"
    />
    <text-field
      v-if="fields.includes('name')"
      v-model="form.name"
      type="text"
      :placeholder="$t('name')"
      :error-messages="errors.name"
      @input="errors.name = []"
    />
    <text-field
      v-if="fields.includes('password')"
      v-model="form.password"
      type="password"
      :placeholder="$t('password')"
      :error-messages="errors.password"
      @input="errors.password = []"
    />
    <text-field
      v-if="fields.includes('password_confirmation')"
      v-model="form.password_confirmation"
      type="password"
      :placeholder="$t('password_confirmation')"
      :error-messages="errors.password_confirmation"
      @input="errors.password_confirmation = []"
    />
    <div v-if="fields.includes('messenger')" class="messengers">
      <select-field
        v-model="form.messenger"
        :items="clickadillaMessengers"
        :placeholder="$t('messenger')"
        :error-messages="errors.messenger"
      />
      <text-field
        v-model="form.messenger_nickname"
        type="text"
        :placeholder="$t('messenger_nickname')"
        :error-messages="errors.messenger_nickname"
        @input="errors.messenger_nickname = []"
      />
    </div>
    <div v-if="fields.includes('second_messenger')" class="messengers">
      <select-field
        v-model="form.second_messenger"
        :items="clickadillaMessengers"
        :placeholder="$t('second_messenger')"
        :error-messages="errors.second_messenger"
      />
      <text-field
        v-model="form.second_messenger_nickname"
        type="text"
        :placeholder="$t('second_messenger_nickname')"
        :error-messages="errors.second_messenger_nickname"
        @input="errors.second_messenger_nickname = []"
      />
    </div>
    <text-field
      v-if="fields.includes('phone')"
      v-model="form.phone"
      type="text"
      :placeholder="$t('phone')"
      :error-messages="errors.phone"
      @input="errors.phone = []"
    />
  </div>
</template>

<script>
import TextField from '@/components/TextField'
import SelectField from '@/components/SelectField'

export default {
  name: 'ClickadillaView',
  components: { SelectField, TextField },
  inject: ['eventBus'],
  props: {
    fields: Array,
    errors: Object
  },
  data() {
    return {
      form: {
        email: '',
        name: '',
        password: '',
        password_confirmation: '',
        messenger: '',
        messenger_nickname: '',
        second_messenger: '',
        second_messenger_nickname: '',
        phone: ''
      },
      clickadillaMessengers: [
        { value: 'skype', text: 'Skype' },
        { value: 'telegram', text: 'Telegram' },
        { value: 'wechat', text: 'Wechat' },
        { value: 'facebook', text: 'Facebook' },
        { value: 'whatsapp', text: 'Whatsapp' },
        { value: 'line', text: 'Line' },
        { value: 'icq', text: 'ICQ' },
        { value: 'qq', text: 'QQ' },
        { value: 'other', text: 'Other' }
      ]
    }
  },
  created() {
    this.eventBus.$on('setField', ({ field, value }) => {
      this.form[field] = value
    })
  },
  methods: {
    register(gRecaptchaResponse) {
      this.$emit('register', {
        form: this.form,
        gRecaptchaResponse,
        additionalFields: {
          is_widget: 1,
          account_type: 'personal'
        }
      })
    }
  }
}
</script>

<style lang="scss" scoped>
.messengers {
  @media (min-width: 480px) {
    display: flex;
    justify-content: space-between;
    > * {
      width: 49%;
    }
  }
}
</style>
