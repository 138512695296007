<template>
  <div class="o-field-wrapper">
    <div
      :class="[
        'user-widget-field',
        {
          'user-widget-field--errored': hasErrors
        }
      ]"
    >
      <input
        v-model="text"
        :type="type"
        :placeholder="placeholder"
        class="user-widget-field__input"
        @input="$emit('update')"
      />
    </div>

    <ul v-if="hasErrors" class="user-widget-field__error">
      <li v-for="(error, i) in errorMessages" :key="i">
        {{ error }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'TextField',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    value: {
      type: [String, Number],
      default: ''
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    text: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    hasErrors() {
      return this.errorMessages && this.errorMessages.length
    }
  }
}
</script>

<style lang="scss" scoped>
.o-field-wrapper {
  min-height: 62px;
  margin-bottom: 5px;
}

.user-widget-field {
  position: relative;
  width: 100%;
  box-shadow: 0 0 10px rgba(#000, 0.1);
  background: #fff;
  border-radius: 20px;
  border: 2px solid #fff;

  &--errored {
    border: 2px solid rgba(#f00, 0.8);
  }

  &__error {
    font-size: 12px;
    color: #f00;
    padding-left: 20px;
    margin-top: 2px;
  }

  &__input {
    padding: 12px 20px;
    height: 41px;
    outline: none;
    width: 100%;
  }
}
</style>
