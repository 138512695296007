<template>
  <div class="user-widget-selector-wrapper">
    <div
      :class="[
        'user-widget-field',
        {
          'user-widget-field--errored': hasErrors
        }
      ]"
    >
      <select v-model="select" class="user-widget-field__select">
        <option value="">{{ placeholder }}</option>
        <option v-for="item in items" :key="item.value" :value="item.value">
          {{ item.text }}
        </option>
      </select>
    </div>

    <ul v-if="hasErrors" class="user-widget-field__error">
      <li v-for="(error, i) in errorMessages" :key="i">
        {{ error }}
      </li>
    </ul>
  </div>
</template>

<script>
export default {
  name: 'SelectField',
  props: {
    type: {
      type: String,
      default: 'text'
    },
    placeholder: {
      type: String,
      default: ''
    },
    items: {
      type: Array,
      default: () => []
    },
    value: {
      type: [String, Number],
      default: ''
    },
    errorMessages: {
      type: Array,
      default: () => []
    }
  },
  computed: {
    select: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    hasErrors() {
      return this.errorMessages && this.errorMessages.length
    }
  }
}
</script>

<style scoped lang="scss">
select {
  -webkit-appearance: none;
  -moz-appearance: none;
  background-image: url("data:image/svg+xml;utf8,<svg fill='black' height='24' viewBox='0 0 24 24' width='24' xmlns='http://www.w3.org/2000/svg'><path d='M7 10l5 5 5-5z'/><path d='M0 0h24v24H0z' fill='none'/></svg>");
  background-repeat: no-repeat;
  background-position-x: 97%;
  background-position-y: 50%;
}

.user-widget-selector-wrapper {
  min-height: 62px;
  margin-bottom: 5px;
}

.user-widget-field {
  position: relative;
  width: 100%;
  box-shadow: 0 0 10px rgba(#000, 0.1);
  background: #fff;
  border-radius: 20px;
  border: 2px solid #fff;

  &--errored {
    border: 2px solid rgba(#f00, 0.8);
  }

  &__error {
    font-size: 12px;
    color: #f00;
    padding-left: 20px;
    margin-top: 2px;
  }

  &__select {
    padding: 12px 20px;
    height: 41px;
    outline: none;
    width: 100%;
  }
}
</style>
