<template>
  <ul class="user-widget__error">
    <li v-for="(error, idx) in errors" :key="idx">
      {{ error }}
    </li>
  </ul>
</template>

<script>
export default {
  name: 'RecaptchaErrors',
  props: {
    errors: {
      type: Array,
      default: () => []
    }
  }
}
</script>
