export default function getUrlQueryParams(url) {
  const splittedUrl = url.split('?')
  if (!splittedUrl[1]) {
    return {}
  }

  return splittedUrl[1].split('&').reduce((acc, paramKeyEqValue) => {
    const [key, value] = paramKeyEqValue.split('=')
    acc[key] = value

    return acc
  }, {})
}
