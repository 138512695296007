<template>
  <button
    v-on="$listeners"
    :class="[
      'user-widget-button',
      `user-widget-button--${type}`,
      {
        'user-widget-button--loading': loading
      }
    ]"
    :loading="loading"
  >
    <slot />
  </button>
</template>

<script>
export default {
  props: {
    loading: Boolean,
    type: {
      type: String,
      required: true
    }
  }
}
</script>

<style lang="scss" scoped>
.user-widget-button {
  padding: 8px;
  height: 35px;
  font-weight: bold;
  cursor: pointer;
  border-radius: 20px;
  outline: none;
  color: #fff;
  text-align: center;
  width: 130px;

  &--clickadilla {
    background: #9ed04d;
  }

  &--vast-service {
    background: linear-gradient(
      180deg,
      rgba(25, 206, 204, 1) 0%,
      rgba(12, 165, 233, 1) 100%
    );
  }
}
</style>
