import getUrlQueryParams from '@/utils/get-url-query-params'
import Cookies from 'js-cookie'
import getDomainName from '@/utils/getDomainName'

export function saveAffiliateCode() {
  const query = getUrlQueryParams(window.location.href)
  if (query.ref) {
    Cookies.set('affiliate_code', query.ref, {
      expires: 7,
      domain:
        process.env.NODE_ENV === 'production'
          ? `.${getDomainName(window.location.hostname)}`
          : ''
    })
  }
}

export function getSavedAffiliateCode() {
  return Cookies.get('affiliate_code')
}
