export default eventBus => ({
  setField(field, value) {
    eventBus.$emit('setField', {
      field,
      value
    })
  },

  register() {
    eventBus.$emit('register')
    return new Promise((resolve, reject) => {
      eventBus.$on('register:error', data => {
        reject(data)
      })
    })
  }
})
