<template>
  <div>
    <text-field
      v-model="form.email"
      type="email"
      :placeholder="$t('email')"
      :error-messages="errors.email"
      @input="errors.email = []"
    />
    <text-field
      v-if="fields.includes('name')"
      v-model="form.name"
      type="text"
      :placeholder="$t('name')"
      :error-messages="errors.name"
      @input="errors.name = []"
    />
    <text-field
      v-if="fields.includes('password')"
      v-model="form.password"
      type="password"
      :placeholder="$t('password')"
      :error-messages="errors.password"
      @input="errors.password = []"
    />
    <text-field
      v-if="fields.includes('password_confirmation')"
      v-model="form.password_confirmation"
      type="password"
      :placeholder="$t('password_confirmation')"
    />
    <text-field
      v-if="fields.includes('skype')"
      v-model="form.skype"
      type="text"
      :placeholder="$t('skype')"
      :error-messages="errors.skype"
      @input="errors.skype = []"
    />
    <text-field
      v-if="fields.includes('telegram')"
      v-model="form.telegram"
      type="text"
      :placeholder="$t('telegram')"
      :error-messages="errors.telegram"
      @input="errors.telegram = []"
    />
    <text-field
      v-if="fields.includes('additional_info')"
      v-model="form.additional_info"
      type="text"
      :placeholder="$t('additional_info')"
      :error-messages="errors.additional_info"
      @input="errors.additional_info = []"
    />
  </div>
</template>

<script>
import TextField from '@/components/TextField'

export default {
  name: 'VastServiceView',
  components: { TextField },
  inject: ['eventBus'],
  props: {
    fields: Array,
    errors: Object
  },
  data() {
    return {
      form: {
        email: '',
        name: '',
        password: '',
        password_confirmation: '',
        skype: '',
        telegram: '',
        additional_info: ''
      }
    }
  },
  created() {
    this.eventBus.$on('setField', ({ field, value }) => {
      this.form[field] = value
    })
  },
  methods: {
    register(gRecaptchaResponse) {
      this.$emit('register', {
        form: this.form,
        gRecaptchaResponse
      })
    }
  }
}
</script>
