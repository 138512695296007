<template>
  <div id="app" class="user-widget">
    <div class="user-widget__inner">
      <captcha
        v-if="!isRegistered"
        ref="captcha"
        v-slot="{ performVerifiedAction }"
        :sitekey="recaptchaKey"
        @verified="$refs.form.register($event)"
      >
        <component
          ref="form"
          :fields="fields"
          :errors="errors"
          :is="form"
          @register="register"
        />

        <btn
          :loading="loading"
          :type="type"
          :class="isOnlyEmailField ? 'user-widget__button--solo' : ''"
          @click="performVerifiedAction"
        >
          <i class="fa fa-circle-o-notch fa-spin" v-if="loading" />
          <span> {{ loading ? $t('loading') : $t('register') }}</span>
        </btn>

        <recaptcha-errors :errors="errors['g-recaptcha-response']" />
      </captcha>
      <div v-else class="user-widget__inner success-register">
        <p>REGISTRATION SUCCEEDED!</p>
        <p>Please check and confirm your email!</p>
      </div>
    </div>
  </div>
</template>

<script>
import VastServiceView from '@/views/VastServiceView'
import Captcha from '@/components/Captcha'
import RecaptchaErrors from '@/components/RecaptchaErrors'
import Btn from '@/components/Btn'
import ClickadillaView from '@/views/ClickadillaView'
import gtmPush from '@/utils/gtm-push'
import { getSavedAffiliateCode } from '@/utils/save-affiliate-code'

export default {
  name: 'App',
  components: {
    ClickadillaView,
    Btn,
    RecaptchaErrors,
    Captcha,
    VastServiceView
  },
  inject: ['eventBus'],
  props: {
    type: {
      type: String,
      required: true
    },
    api: {
      type: Function,
      required: true
    },
    fields: {
      type: Array,
      required: true
    },
    recaptchaKey: {
      type: String,
      required: true
    }
  },
  data: () => ({
    isRegistered: false,
    loading: false,
    errors: {}
  }),
  computed: {
    form() {
      return {
        clickadilla: 'ClickadillaView',
        'vast-service': 'VastServiceView'
      }[this.type]
    },
    isOnlyEmailField() {
      return this.fields.length === 0
    }
  },
  created() {
    this.eventBus.$on('register', () => {
      this.$refs.captcha.performVerifiedAction()
    })
  },
  methods: {
    async register({ form, gRecaptchaResponse, additionalFields }) {
      const createBodyFromSelectedFields = () => {
        const body = this.fields.reduce((acc, field) => {
          if (field in form) {
            acc[field] = form[field]
          }
          return acc
        }, {})
        body.email = form.email
        return body
      }

      try {
        const { data } = await this.api.post('/register', {
          ...createBodyFromSelectedFields(),
          ...additionalFields,
          affiliate_code: getSavedAffiliateCode(),
          'g-recaptcha-response': gRecaptchaResponse
        })

        gtmPush({
          event: 'reg_form_submitted_successfully',
          eventCategory: 'registration',
          custom_map: { dimension3: 'UserReg' },
          user_id: data.data.id,
          UserReg: data.data.id
        })

        this.eventBus.$emit('registered', {
          token: data.data.auth_token,
          email: form.email
        })

        this.isRegistered = true
      } catch (error) {
        this.eventBus.$emit('registration_error', {
          event: 'registration_error'
        })
        if (
          error &&
          error.response &&
          error.response.data &&
          error.response.data.errors
        ) {
          this.errors = error.response.data.errors
          this.eventBus.$emit('register:error', {
            errors: error.response.data.errors,
            message: error.response.data.message
          })
        }
      }
    }
  }
}
</script>

<style lang="scss">
.user-widget {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;

  * {
    margin: 0;
    padding: 0;
    background: unset;
    border: 0;
    box-sizing: border-box;
  }

  &__inner {
    max-width: 700px;
    margin: 0 auto;
    position: relative;

    .success-register {
      padding: 20px;
      border-radius: 4px;
      text-align: center;
      background: #9ed04d;
      color: #fff;
    }
  }

  &__button--solo {
    position: absolute;
    top: 5px;
    right: 5px;
  }

  &__error {
    font-size: 12px;
    color: #f00;
    padding-left: 20px;
    margin-top: 2px;
  }
}
</style>
