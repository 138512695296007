import Vue from 'vue'
import createI18N from './createI18N'
import VueSimpleAlert from 'vue-simple-alert'
import { saveAffiliateCode } from '@/utils/save-affiliate-code'
import App from '@/App'
import createApi from '@/utils/createApi'
import createEventBus from '@/utils/create-event-bus'
import createJSApi from './createJSApi'

Vue.use(VueSimpleAlert)
saveAffiliateCode()

Vue.config.productionTip = false

window.userWidget = function({
  type = 'vast-service',
  mountTarget = '#user-widget',
  apiUrl,
  recaptchaKey,
  language,
  fields = [],
  captchaKey,
  afterRegister
}) {
  const i18n = createI18N(language)
  const api = createApi(apiUrl)
  const eventBus = createEventBus()

  eventBus.$on('registered', afterRegister || (() => {}))

  const processedSettings = () => {
    return {
      type,
      api,
      fields,
      recaptchaKey: recaptchaKey ? recaptchaKey : captchaKey
    }
  }

  new Vue({
    i18n,
    provide: {
      eventBus
    },
    render: h =>
      h(App, {
        props: {
          ...processedSettings()
        }
      })
  }).$mount(mountTarget)

  const jsApi = createJSApi(eventBus)
  window.userWidget.instances.push(jsApi)
  return jsApi
}

window.userWidget.instances = []
window.vastServiceUserWidget = window.userWidget
window.userWidget.hash = Math.random()
